import React from "react"
import { Blank } from "grommet-icons"

const Vessel = ({ style, ...rest }) => {
  return (
    <Blank style={{ height: "24px", width: "24px", ...style }} {...rest}>
      <svg viewBox="0 0 91 91" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.78299 19.47C10.365 20.013 12.832 19.277 15.006 18.626C16.802 18.089 18.5 17.579 20.01 17.827C20.59 17.922 21.074 18.129 21.373 18.408C21.607 18.628 21.712 18.911 21.691 19.067C21.543 20.217 22.353 21.27 23.504 21.421C23.596 21.433 23.687 21.439 23.777 21.439C24.709 21.439 25.525 20.817 25.785 19.927C25.802 19.884 25.816 19.833 25.829 19.772C25.84 19.721 25.844 19.675 25.853 19.625C25.854 19.619 25.856 19.615 25.857 19.609C25.857 19.606 25.857 19.603 25.857 19.6C26.468 16.523 25.932 14.066 24.248 12.302C21.227 9.13599 15.623 9.65599 13.358 10.027C11.363 10.35 9.16399 9.32399 8.13399 7.58199C7.54399 6.58399 6.25699 6.25399 5.25699 6.84199C4.25999 7.43199 3.92899 8.71999 4.51899 9.71799C6.42299 12.941 10.335 14.771 14.034 14.173C14.684 14.067 15.396 13.993 16.117 13.964C15.315 14.152 14.54 14.382 13.802 14.603C11.945 15.16 10.189 15.684 8.64899 15.361C7.62699 15.146 6.68699 14.532 6.07199 13.677C5.39399 12.736 4.08199 12.521 3.13999 13.199C2.19899 13.877 1.98599 15.189 2.66299 16.13C3.88099 17.825 5.74799 19.042 7.78299 19.47Z"></path>
        <path d="M89.873 41.116C89.482 40.541 88.832 40.196 88.137 40.196H80.212V34.924C80.212 33.765 79.272 32.823 78.111 32.823H74.582V23.673C74.582 22.513 73.641 21.572 72.481 21.572H47.863C46.704 21.572 45.763 22.512 45.763 23.673V32.823H41.185C40.025 32.823 39.084 33.764 39.084 34.924V41.405L36.108 34.129C35.786 33.34 35.018 32.823 34.165 32.823H31.206V27.131C31.206 25.972 30.265 25.03 29.105 25.03H20.827C19.667 25.03 18.727 25.971 18.727 27.131V32.823H15.77C14.917 32.823 14.149 33.34 13.827 34.13L9.76299 44.075H3.58299C2.89199 44.075 2.24399 44.415 1.85299 44.985C1.45999 45.554 1.37399 46.28 1.62299 46.926L11.582 72.93C11.996 74.013 13.211 74.555 14.293 74.14C15.377 73.725 15.918 72.511 15.504 71.428L10.285 57.8H72.483C73.642 57.8 74.584 56.861 74.584 55.7C74.584 54.539 73.643 53.6 72.483 53.6H8.67499L6.63499 48.274H73.176C74.025 48.274 74.789 47.763 75.115 46.978L76.187 44.396H85.046L73.986 72.342C73.559 73.42 74.088 74.641 75.166 75.068C75.42 75.168 75.681 75.215 75.938 75.215C76.774 75.215 77.565 74.712 77.891 73.887L90.087 43.068C90.345 42.423 90.264 41.691 89.873 41.116ZM22.928 29.231H27.006V32.823H22.928V29.231ZM14.299 44.075L17.18 37.024H20.828H29.106H32.755L35.638 44.075H14.299ZM51.395 44.075H43.286V37.024H47.864H51.395V44.075ZM49.964 32.823V25.772H58.073V32.823H53.494H49.964ZM63.704 44.075H55.595V37.024H60.174H63.704V44.075ZM62.273 32.823V25.772H70.38V32.823H65.804H62.273ZM76.012 40.196H74.785C73.935 40.196 73.17 40.708 72.846 41.492L71.774 44.075H71.078H67.905V37.024H72.482H76.012V40.196Z"></path>
        <path d="M88.539 77.839C87.468 77.391 86.238 77.894 85.789 78.963C84.758 81.421 82.041 83.047 79.061 82.955C76.076 82.884 73.474 81.161 72.587 78.669C72.197 77.575 70.995 77.005 69.904 77.395C69.392 77.577 69.003 77.94 68.764 78.384C68.738 78.414 68.707 78.462 68.67 78.551C67.641 81.007 64.92 82.63 61.943 82.542C58.958 82.472 56.357 80.75 55.47 78.259C55.467 78.251 55.464 78.247 55.462 78.239C55.27 77.706 54.869 77.249 54.306 77.011C53.236 76.562 52.005 77.065 51.556 78.134C50.523 80.593 47.804 82.228 44.825 82.128C41.842 82.059 39.241 80.336 38.354 77.843C37.965 76.751 36.765 76.178 35.671 76.569C35.201 76.736 34.836 77.058 34.594 77.454C34.547 77.501 34.496 77.588 34.439 77.725C33.408 80.185 30.667 81.816 27.709 81.715C24.725 81.646 22.123 79.924 21.236 77.431C21.232 77.421 21.228 77.417 21.224 77.408C21.031 76.877 20.631 76.424 20.069 76.187C19 75.738 17.769 76.243 17.32 77.312C16.291 79.769 13.56 81.391 10.594 81.305C7.607 81.234 5.007 79.512 4.124 77.021C3.737 75.927 2.536 75.354 1.444 75.743C0.349998 76.131 -0.222002 77.331 0.164998 78.424C1.626 82.546 5.776 85.392 10.495 85.505C10.589 85.507 10.683 85.508 10.776 85.508C14.031 85.508 17.064 84.193 19.149 82.021C21.169 84.359 24.243 85.837 27.608 85.916C27.707 85.918 27.803 85.919 27.901 85.919C31.152 85.919 34.182 84.605 36.266 82.435C38.286 84.773 41.36 86.251 44.725 86.331C44.82 86.333 44.914 86.334 45.008 86.334C48.262 86.334 51.295 85.019 53.381 82.848C55.401 85.186 58.476 86.664 61.844 86.744C61.938 86.746 62.032 86.747 62.125 86.747C65.381 86.747 68.414 85.432 70.498 83.261C72.518 85.598 75.592 87.076 78.959 87.157C79.053 87.159 79.147 87.16 79.24 87.16C83.834 87.16 87.998 84.547 89.658 80.591C90.111 79.519 89.608 78.288 88.539 77.839Z"></path>
      </svg>
    </Blank>
  )
}

export default Vessel
