import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Landing from "./spoor/Landing"
import SupplyChain from "./spoor/SupplyChain"
import TripManagement from "./spoor/TripManagement"
import DeviceAgnostic from "./spoor/DeviceAgnostic"
import WantToKnowMore from "./spoor/WantToKnowMore"
import ProductTypes from "./spoor/ProductTypes"
import BlogPosts from "../components/Sections/BlogPosts"

const Index = () => {
  return (
    <Layout>
      <SEO title="Home" />

      <Landing />
      <SupplyChain />
      <TripManagement />
      <DeviceAgnostic />
      <ProductTypes />
      {/* <BlogPosts /> */}
      <WantToKnowMore />
    </Layout>
  )
}

export default Index
