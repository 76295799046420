import React from "react"
import styled from "styled-components"
import { Paragraph, Button, Box, ResponsiveContext } from "grommet"
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import { themeExtension } from "../../styles/theme"
import WaypointLabel from "../../components/Labels/WaypointLabel"
import { Port, Handshake, Terminal } from "../../components/Icons"
import Title from "../../components/Typography/Title"
import SupplyChainRoute from "../../assets/SupplyChainRoute.svg"
import SupplyChainRouteMedium from "../../assets/SupplyChainRouteMedium.svg"
import SVCBig from "../../assets/SCVAbove500px.svg"

const StyledWrapperBox = styled(Box)`
  margin-top: -5px;
  z-index: 1;
  background-color: ${themeExtension.colors.backgrounds.light};
`

const StyledParagraph = styled(Paragraph)`
  display: none;
  @media (min-width: 600px) {
    display: block;
  }
`

const RoadBox = styled(Box)`
  right: 0;
  top: 0;
  position: absolute;
  flex-direction: row;
  justify-content: flex-end;
  align-itmes: flex-end;
`
const PrimaryButton = styled(Button)`
  width: ${props => props.width};
  text-align: center;
  font-weight: 300;
  z-index: 2;
  height: 40px;
`

const SupplyChain = () => {
  const { title, subtitle, paragraph } = useSpoorData()
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <StyledWrapperBox>
          <Box
            align="center"
            direction="column"
            pad={{
              bottom: "xlarge",
            }}
          >
            <Box width="xlarge" style={{ position: "relative" }}>
              {size === "small" ? (
                <Box>
                  <SVCBig />
                </Box>
              ) : (
                <RoadBox>
                  <WaypointLabel
                    circleColor="orange"
                    title="At Port"
                    style={
                      size === "medium"
                        ? { right: "89px", top: "62px" }
                        : { right: "172px", top: "62px" }
                    }
                  >
                    <Port color="orange" />
                  </WaypointLabel>
                  <WaypointLabel
                    alignLeft
                    circleColor="lightGreen"
                    title="Terminal"
                    style={
                      size === "medium"
                        ? { right: "9px", top: "187px" }
                        : { right: "90px", top: "187px" }
                    }
                  >
                    <Terminal color="#2EE7AF" />
                  </WaypointLabel>
                  <WaypointLabel
                    circleColor="lightBlue"
                    title="Delivered"
                    style={
                      size === "medium"
                        ? { right: "108px", top: "324px" }
                        : { right: "186px", top: "324px" }
                    }
                  >
                    <Handshake color="#50DCDA" />
                  </WaypointLabel>

                  {size === "medium" ? (
                    <SupplyChainRouteMedium />
                  ) : (
                    <SupplyChainRoute />
                  )}
                </RoadBox>
              )}
              <Box
                align="center"
                justify={size === "small" ? "center" : "start"}
                direction="column"
                style={
                  size === "small"
                    ? { position: "absolute", height: "100%" }
                    : {}
                }
              >
                <Box
                  pad={
                    size === "small"
                      ? {}
                      : {
                          top: "xlarge",
                          horizontal: "large",
                        }
                  }
                  width="xlarge"
                  justify="start"
                  style={size === "small" ? {} : { minHeight: 415 }}
                  direction="row"
                >
                  <Box
                    basis={size === "small" ? "full" : "2/3"}
                    justify="center"
                    pad={
                      size !== "small"
                        ? { right: "xlarge" }
                        : { top: "xlarge", horizontal: "large", bottom: "none" }
                    }
                  >
                    {size === "small" ? (
                      <Box
                        style={{
                          boxShadow: themeExtension.shadows.graySoft,
                          zIndex: 2,
                        }}
                        round="small"
                        pad="large"
                        background="rgba(255,255,255, 0.89)"
                        animation="slideLeft"
                        margin={size === "small" ? { bottom: "none" } : {}}
                      >
                        <Title
                          title={title}
                          subTitle={subtitle}
                          align="center"
                          desc
                          paddingBottom="none"
                        />
                        <Box pad={{ right: size }}>
                          <StyledParagraph
                            textAlign="center"
                            fill
                            margin="none"
                            size="medium"
                            color="dark-3"
                          >
                            <ReactMarkdown
                              source={paragraph}
                              disallowedTypes={["paragraph"]}
                              unwrapDisallowed={true}
                            />
                          </StyledParagraph>
                        </Box>
                        <Box pad={{ top: "medium" }} align="center">
                          <a
                            href="mailto:founders@periplus.ch?subject=Tell%20me%20more%20-%20CTA%20Work&body=I'm%20interested%20in%20learning%20more%20on%20how%20PeriPlus%20can%20help%20me."
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <PrimaryButton
                              width={size === "small" ? "150px" : "210px"}
                              primary
                              color="brand"
                              hoverIndicator
                              onClick={() => {
                                console.log("heyhey")
                              }}
                            >
                              {size === "small"
                                ? "More Info"
                                : "I want to know more"}
                            </PrimaryButton>
                          </a>
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        <Title
                          title={title}
                          tlevel={2}
                          subTitle={subtitle}
                          align="start"
                          desc
                          paddingBottom="none"
                        />

                        <Box pad={{ right: size }}>
                          <StyledParagraph
                            textAlign="start"
                            fill
                            margin="none"
                            size="medium"
                            color="dark-3"
                          >
                            <ReactMarkdown
                              source={paragraph}
                              disallowedTypes={["paragraph"]}
                              unwrapDisallowed={true}
                            />
                          </StyledParagraph>
                        </Box>

                        <Box pad={{ top: "medium" }}>
                          <Box>
                            <a
                              href="mailto:founders@periplus.ch?subject=Tell%20me%20more%20-%20CTA%20Work&body=I'm%20interested%20in%20learning%20more%20on%20how%20PeriPlus%20can%20help%20me."
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <PrimaryButton
                                width={size === "small" ? "150px" : "210px"}
                                primary
                                color="brand"
                                hoverIndicator
                                onClick={() => {
                                  console.log("heyhey")
                                }}
                              >
                                {size === "small"
                                  ? "More Info"
                                  : "I want to know more"}
                              </PrimaryButton>
                            </a>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledWrapperBox>
      )}
    </ResponsiveContext.Consumer>
  )
}

export const useSpoorData = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query All {
        markdownRemark(
          frontmatter: { identification: { in: "SpoorSupplyChain" } }
        ) {
          frontmatter {
            title: supplychain_title
            subtitle: supplychain_subtitle
            paragraph: supplychain_paragraph
            buttonTitle: supplychain_button_title
            supplychain_button_link
          }
        }
      }
    `
  )
  return markdownRemark.frontmatter
}

export default SupplyChain
