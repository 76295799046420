import React from "react"
import { Blank } from "grommet-icons"

const Port = ({ style, ...rest }) => {
  return (
    <Blank style={{ height: "24px", width: "24px", ...style }} {...rest}>
      <svg viewBox="0 0 91 91" xmlns="http://www.w3.org/2000/svg">
        <path d="M89.114 38.134C89.084 38.113 89.057 38.093 89.021 38.073L73.691 29.84V15.824C73.691 14.664 72.75 13.724 71.59 13.724H52.357V10.031C52.357 9.26499 51.941 8.56099 51.271 8.19099L38.246 1.01699C37.935 0.845988 37.588 0.756989 37.232 0.756989H17.245C16.086 0.756989 15.144 1.69599 15.144 2.85699V13.725H3.392C2.233 13.725 1.291 14.664 1.291 15.825V21C1.291 22.16 2.232 23.1 3.392 23.1C4.552 23.1 5.493 22.161 5.493 21V17.925H15.145V33.023L5.854 86.108H3.392C2.233 86.108 1.291 87.047 1.291 88.208C1.291 89.369 2.232 90.308 3.392 90.308H55.154C56.313 90.308 57.255 89.369 57.255 88.208C57.255 87.047 56.314 86.108 55.154 86.108H49.693L40.4 33.017V17.924H69.49V29.84L54.16 38.073C54.12 38.094 54.092 38.116 54.059 38.137C53.457 38.506 53.052 39.164 53.052 39.922V60.04C53.052 61.2 53.993 62.14 55.153 62.14H88.028C89.187 62.14 90.129 61.201 90.129 60.04V39.923C90.129 39.162 89.72 38.502 89.114 38.134ZM19.346 4.95599H36.694L48.159 11.272V13.724H19.346V4.95599ZM36.201 31.101H23.48L36.201 21.438V31.101ZM32.898 35.3L27.772 39.994L22.647 35.3H32.898ZM33.883 17.924L19.346 28.968V17.924H33.883ZM18.653 37.338L24.662 42.843L16.358 50.447L18.653 37.338ZM18.845 53.867L27.772 45.691L36.699 53.867H18.845ZM36.617 58.066L27.771 66.004L18.925 58.066H36.617ZM14.701 59.918L24.629 68.826L11.002 81.056L14.701 59.918ZM27.772 71.647L43.886 86.108H11.66L27.772 71.647ZM30.916 68.826L40.845 59.916L44.545 81.057L30.916 68.826ZM30.882 42.843L36.894 37.337L39.189 50.451L30.882 42.843ZM71.591 33.478L79.682 37.824H63.5L71.591 33.478ZM85.928 57.941H57.254V42.022H85.928V57.941Z"></path>
        <path d="M63.829 56.675C64.988 56.675 65.93 55.736 65.93 54.575V45.841C65.93 44.681 64.989 43.741 63.829 43.741C62.669 43.741 61.728 44.68 61.728 45.841V54.575C61.729 55.735 62.67 56.675 63.829 56.675Z"></path>
        <path d="M71.591 56.675C72.75 56.675 73.692 55.736 73.692 54.575V45.841C73.692 44.681 72.751 43.741 71.591 43.741C70.431 43.741 69.49 44.68 69.49 45.841V54.575C69.49 55.735 70.432 56.675 71.591 56.675Z"></path>
        <path d="M79.353 56.675C80.512 56.675 81.454 55.736 81.454 54.575V45.841C81.454 44.681 80.513 43.741 79.353 43.741C78.193 43.741 77.252 44.68 77.252 45.841V54.575C77.252 55.735 78.193 56.675 79.353 56.675Z"></path>
        <path d="M34.357 7.05698H25.236C24.077 7.05698 23.136 7.99598 23.136 9.15698C23.136 10.318 24.076 11.257 25.236 11.257H34.357C35.516 11.257 36.457 10.318 36.457 9.15698C36.457 7.99598 35.517 7.05698 34.357 7.05698Z"></path>
      </svg>
    </Blank>
  )
}

export default Port
