import React from "react"
import { Box, ResponsiveContext, Paragraph, Heading } from "grommet"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Wrapper from "../../components/Container/Wrapper"
import API from "../../assets/API.svg"
import Link from "../../assets/Link.svg"
import RMGMT from "../../assets/RMGMT.svg"
import Title from "../../components/Typography/Title"

const data = [
  {
    id: 1,
    title: "Easy Hook-up",
    description:
      "Connect with IoT devices on the fly. You can choose between SMS, WhatsApp, Telegram, iOS/Android app or our web platform.",
    icon: <Link />,
  },
  {
    id: 2,
    title: "Event Management",
    description:
      "Automate your supply chain event management through location interactions and IoT device inputs and easily manage advanced reporting and notifications.",
    icon: <RMGMT />,
  },
  {
    id: 3,
    title: "Third Party API Integration",
    description:
      "Spoor securely links IoT devices and other data sources with your transportation management system to generate real visibility of your supply chain.",
    icon: <API />,
  },
]

const TripManagement = () => {
  const imageData = useStaticQuery(graphql`
    query {
      pairing: file(relativePath: { eq: "WhatsAppAngle.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mgmt: file(relativePath: { eq: "SpoorWPMgmt.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      route: file(relativePath: { eq: "APICode.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Wrapper>
          <Title
            tlevel={2}
            align="center"
            subTitle="Some of our core Features"
            title="Effective Tools"
            desc
          />
          {data.map((item, i) => (
            <Box
              key={item.title}
              direction={
                size === "small" ? "column" : i % 2 ? "row-reverse" : "row"
              }
              justify="center"
              gap={size === "small" ? "medium" : "large"}
            >
              <Box direction="column" basis="1/3" justify="center">
                <Box
                  style={
                    size === "small"
                      ? { width: 70, height: 70 }
                      : { width: 110, height: 110 }
                  }
                >
                  {item.icon}
                </Box>
                <Heading
                  level={3}
                  color="dark-2"
                  margin={
                    size === "small"
                      ? { bottom: "none", top: "xsmall" }
                      : { bottom: "none", top: "small" }
                  }
                >
                  {item.title}
                </Heading>
                <Paragraph
                  margin={{ top: "xsmall" }}
                  size={size === "small" ? "small" : "medium"}
                  color="dark-5"
                  style={size === "small" ? { maxWidth: "100%" } : {}}
                >
                  {item.description}
                </Paragraph>
              </Box>

              <Box
                basis={size === "small" ? "full" : "1/2"}
                margin={
                  size === "small"
                    ? { bottom: "xlarge" }
                    : { vertical: "xlarge" }
                }
                direction={size === "small" ? "row" : "column"}
                justify={size === "small" ? "center" : "start"}
              >
                <Box
                  style={
                    size === "small"
                      ? item.id === 1
                        ? { maxWidth: "50%", width: "100%" }
                        : item.id === 2
                        ? { maxWidth: "80%", width: "100%" }
                        : item.id === 3
                        ? { maxWidth: "80%", width: "100%" }
                        : {}
                      : {}
                  }
                >
                  <Img
                    fluid={
                      i === 0
                        ? imageData.pairing.childImageSharp.fluid
                        : i === 1
                        ? imageData.mgmt.childImageSharp.fluid
                        : imageData.route.childImageSharp.fluid
                    }
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Wrapper>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default TripManagement
