import React, { useState } from "react"
import styled from "styled-components"
import {
  Button,
  Box,
  ResponsiveContext,
  ThemeContext,
  TextInput,
  Layer,
  Paragraph,
  Heading,
} from "grommet"

import { themeExtension } from "../../styles/theme"
import Title from "../../components/Typography/Title"
import Wrapper from "../../components/Container/Wrapper"

const WhiteButton = styled(Button)`
  background-color: white;
  width: 210px;
  font-weight: 500;
  margin-left: -30px;
  z-index: 2;
`

const WhiteInput = styled(TextInput)`
  border-color: white;
  color: white;
  border-radius: 12px;
  font-weight: 500;
  padding-right: 40px;
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const WantToKnowMore = () => {
  const [state, setState] = useState({})
  const [dialog, setDialog] = useState(false)

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    }).catch(error => alert(error))
  }

  const handleSubmitClick = () => {
    setDialog(true)
    setTimeout(function() {
      setDialog(false)
    }, 3000)
  }
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          {dialog && (
            <Layer
              position="bottom"
              modal={false}
              margin={{ vertical: "large", horizontal: "none" }}
              onEsc={() => setDialog(false)}
              responsive={false}
              plain
              style={size === "small" ? { width: "80%" } : {}}
            >
              <Box
                align="center"
                direction="column"
                gap="small"
                justify="between"
                round="small"
                elevation="medium"
                pad="medium"
                background="brand"
              >
                <Heading level="3" margin="xsmall" textAlign="center">
                  Thank you for getting in touch!
                </Heading>
                <Paragraph textAlign="center" margin="xsmall">
                  We appreciate you contacting us. One of our colleagues will
                  get back in touch with you soon!
                </Paragraph>
                <Paragraph margin="xsmall">Have a great day!</Paragraph>
              </Box>
            </Layer>
          )}
          <Wrapper background={themeExtension.gradients.dark}>
            <Box
              direction="row"
              width="xxlarge"
              justify="center"
              align="center"
              wrap={size === "small" ? true : false}
            >
              <Box
                basis={size === "small" ? "fill" : "3/4"}
                pad={{ right: "medium", vertical: "small" }}
              >
                <Title
                  title="Interested in collaborating?"
                  paddingBottom="none"
                  tlevel={2}
                  variant="light"
                  subTitle="Every engagement brings great knowledge"
                  align={size === "small" ? "center" : "start"}
                  desc
                />
              </Box>
              <form
                name="collaboratingEmail"
                method="post"
                action="/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <input
                  type="hidden"
                  name="form-name"
                  value="collaboratingEmail"
                />
                <p hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={handleChange} />
                  </label>
                </p>
                <Box direction="row">
                  <ThemeContext.Extend
                    value={{
                      global: {
                        colors: {
                          placeholder: "#FFF",
                        },
                      },
                    }}
                  >
                    <WhiteInput
                      placeholder="Your Mail"
                      type="email"
                      name="email"
                      onChange={handleChange}
                    />

                    <WhiteButton
                      color="white"
                      width="medium"
                      type="submit"
                      label="contact me"
                      onClick={handleSubmitClick}
                    />
                  </ThemeContext.Extend>
                </Box>
              </form>
            </Box>
          </Wrapper>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default WantToKnowMore
