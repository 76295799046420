import React from "react"
import { Box, Paragraph } from "grommet"

import { themeExtension } from "../../styles/theme"
import HollowCircle from "../../components/Circles/HollowCircle"
import FilledCircle from "../../components/Circles/FilledCircle"

const WaypointLabel = ({
  title,
  hollowCircle,
  style,
  alignLeft,
  children,
  variant,
  circleColor,
  size = "small",
}) => {
  const getColor = color => {
    return themeExtension.colors[color] || "#2EE7AF"
  }

  const getBackground = color => {
    return themeExtension.colors.backgrounds[color] || "#FFF"
  }

  const contrastStyle = variant
    ? {
        boxShadow: "2px 4px 0px rgba(0, 0, 0, 0.15)",
        border: "1px solid #333333",
      }
    : {
        boxShadow: "0px 5px 20px rgba(228, 228, 228, 0.9)",
        border: "1px solid #F2F2F2",
      }

  return (
    <Box
      direction="row"
      align="center"
      style={{ position: "absolute", zIndex: 2, ...style }}
    >
      {alignLeft ? (
        hollowCircle ? (
          <HollowCircle color={getColor(circleColor)} />
        ) : (
          <FilledCircle
            color={getColor(circleColor)}
            style={{ ...contrastStyle }}
          />
        )
      ) : null}

      <Box
        direction="row"
        pad={size === "large" ? "15px" : "small"}
        align="center"
        style={{
          borderRadius: 4,
          ...contrastStyle,
        }}
        background={getBackground(variant)}
      >
        <Box
          pad="xsmall"
          justify="center"
          align="center"
          margin={size === "large" ? { right: "15px" } : { right: "small" }}
          style={{ width: 30, height: 30 }}
        >
          {children}
        </Box>
        <Box justify="center">
          <Paragraph
            size={size === "large" ? "medium" : "small"}
            margin={
              size === "large"
                ? { top: "0px", bottom: "0px" }
                : { top: "3px", bottom: "0px" }
            }
          >
            {title}
          </Paragraph>
        </Box>
      </Box>

      {!alignLeft ? (
        hollowCircle ? (
          <HollowCircle align="right" color={getColor(circleColor)} />
        ) : (
          <FilledCircle
            align="right"
            color={getColor(circleColor)}
            style={{ ...contrastStyle }}
          />
        )
      ) : null}
    </Box>
  )
}

export default WaypointLabel
