import React from "react"
import { Box, ResponsiveContext } from "grommet"
import { Waypoint } from "grommet-icons"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Title from "../../components/Typography/Title"
import WaypointLabel from "../../components/Labels/WaypointLabel"
import { themeExtension } from "../../styles/theme"
import LandingRoute from "../../assets/LandingRoute.svg"
import LandingRouteMedium from "../../assets/LandingRouteMedium.svg"
import MapLargeBackground from "../../img/MapLargeBackground.svg"
import MapMediumBackground from "../../img/MapMediumBackground.svg"
import MapAsset from "../../img/Map.svg"
import MapAssetAbove500 from "../../img/MapAbove500px.svg"
import MapAssetMedium from "../../img/MapMedium.svg"
import { Port, Vessel } from "../../components/Icons"

const MapBox = styled(Box)`
  background: ${themeExtension.gradients.greenToLight};
  z-index: 1;

  @media (min-width: 1537px) {
    background: url(${MapLargeBackground}) bottom center no-repeat,
      ${themeExtension.gradients.dark};
    z-index: 0;
  }

  @media (max-width: 1536px) {
    background: url(${MapMediumBackground}) bottom center no-repeat,
      ${themeExtension.gradients.dark};
  }

  @media (max-width: 768px) {
    background: url(${MapAssetAbove500}) bottom center no-repeat,
      ${themeExtension.gradients.dark};
    background-size: 100%;
  }
`

const LandingBox = styled(Box)`
  position: relative;
  height: 70vh;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  @media (min-width: 769px) {
    height: 900px;
  }

  @media (min-width: 1537px) {
    background: url(${MapAsset}) bottom center no-repeat;
  }
  @media (max-width: 1536px) {
    background: url(${MapAssetMedium}) bottom right no-repeat;
  }
  @media (max-width: 768px) {
    background: none;
  }
`

const RoadBox = styled(Box)`
  bottom: 0;
  right: 0;
  z-index: 2;
  position: absolute;
  flex-direction: row;
  justify-content: flex-end;
  align-itmes: flex-end;
`

const Landing = () => {
  const { heading, heading_subtitle } = useSpoorData()
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <MapBox>
          <Box align="center" direction="column">
            <LandingBox width="xlarge">
              {size === "small" ? (
                <Box pad="large" style={{ flex: 1, paddingTop: "120px" }}>
                  <Title
                    title={heading}
                    tlevel={1}
                    subTitle={heading_subtitle}
                    variant="light"
                    align="center"
                  />
                </Box>
              ) : (
                <>
                  {size !== "small" ? (
                    <Box style={{ flex: 1, paddingTop: "125px" }}>
                      <Title
                        title={heading}
                        tlevel={1}
                        subTitle={heading_subtitle}
                        variant="light"
                        align="center"
                      />
                    </Box>
                  ) : null}
                  <RoadBox
                    width={size === "medium" ? "xlarge" : ""}
                    style={
                      size === "small"
                        ? { position: "relative", flex: 1 }
                        : null
                    }
                  >
                    <WaypointLabel
                      alignLeft
                      variant="dark"
                      hollowCircle
                      title="0rigin"
                      style={
                        size === "medium"
                          ? { right: "290px", top: "-22px" }
                          : { right: "372px", top: "-21px" }
                      }
                    >
                      <Waypoint color="#2EE7AF" />
                    </WaypointLabel>

                    <WaypointLabel
                      alignLeft
                      variant="dark"
                      circleColor="lightBlue"
                      hollowCircle
                      title="At Port"
                      style={
                        size === "medium"
                          ? { right: "517px", top: "235px" }
                          : { right: "597px", top: "235px" }
                      }
                    >
                      <Port color="lightBlue" />
                    </WaypointLabel>

                    <WaypointLabel
                      variant="dark"
                      circleColor="orange"
                      hollowCircle
                      title="Departed"
                      style={
                        size === "medium"
                          ? { right: "514px", top: "388px" }
                          : { right: "594px", top: "388px" }
                      }
                    >
                      <Vessel color="orange" />
                    </WaypointLabel>

                    {size === "medium" ? (
                      <LandingRouteMedium />
                    ) : (
                      <LandingRoute />
                    )}
                  </RoadBox>
                </>
              )}
            </LandingBox>
          </Box>
        </MapBox>
      )}
    </ResponsiveContext.Consumer>
  )
}

export const useSpoorData = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query SpoorHeading {
        markdownRemark(
          frontmatter: { identification: { in: "SpoorHeading" } }
        ) {
          frontmatter {
            heading
            heading_subtitle
          }
        }
      }
    `
  )
  return markdownRemark.frontmatter
}

export default Landing
