import React from "react"
import { Box } from "grommet"

const FilledCircle = ({ align, color, style }) => {
  return (
    <Box
      background="white"
      justify="center"
      align="center"
      margin={align ? { left: "small" } : { right: "small" }}
      style={{ width: 37, height: 37, borderRadius: 50, ...style }}
    >
      <Box
        background={color}
        style={{ width: 17, height: 17, borderRadius: 50 }}
      ></Box>
    </Box>
  )
}

export default FilledCircle
