import React from "react"
import { Box, ResponsiveContext, Paragraph } from "grommet"

import Img from "gatsby-image"

import { theme } from "../../styles/theme"
import { useGetProductTypes } from "../../hooks/useGetProductTypes"
import Title from "../../components/Typography/Title"
import Wrapper from "../../components/Container/Wrapper"

const ProductTypes = () => {
  const small = size => (size === "small" ? true : false)
  const data = useGetProductTypes()
  return (
    <ResponsiveContext.Consumer>
      {size =>
        data.map((productType, i) => {
          return productType.publish ? (
            <Wrapper
              key={productType.name}
              overflow="hidden"
              direction={i % 2 ? "row" : "row-reverse"}
              justify="between"
              background={i % 2 ? theme.global.colors.lightBackground : "white"}
              wrap={small(size)}
            >
              <Box
                basis={small(size) ? "full" : "1/3"}
                pad={{
                  vertical: "medium",
                }}
                direction="row"
                align="center"
                wrap
              >
                <Box flex="grow" pad={{ bottom: "small" }}>
                  <Title
                    title={productType.name}
                    tlevel={2}
                    subTitle={productType.subtitle}
                    align={small(size) ? "center" : "start"}
                    paddingBottom="none"
                    desc
                  />
                  {small(size) ? null : (
                    <Paragraph
                      color="dark-5"
                      alignSelf={small(size) ? "center" : "start"}
                      textAlign={small(size) ? "center" : "start"}
                      margin={{ bottom: "none" }}
                    >
                      {productType.body}
                    </Paragraph>
                  )}
                </Box>

                <Box background="accent-1"></Box>
              </Box>

              <Box
                basis={small(size) ? "full" : "2/3"}
                justify="end"
                align={small(size) ? "stretch" : i % 2 ? "start" : "end"}
                pad={i % 2 ? "" : { right: "inherit" }}
              >
                <Img
                  fluid={
                    small(size)
                      ? productType.tinyImage.childImageSharp.fluid
                      : productType.image.childImageSharp.fluid
                  }
                  alt="Project Based Tracking"
                  style={
                    small(size) ? {} : { minWidth: 820, marginBottom: -30 }
                  }
                />
              </Box>
            </Wrapper>
          ) : null
        })
      }
    </ResponsiveContext.Consumer>
  )
}

export default ProductTypes
