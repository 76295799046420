import React from "react"
import { Box, Heading } from "grommet"
import { themeExtension } from "../../styles/theme"

const BoxLabel = ({ size, title, children }) => {
  return (
    <Box pad="small">
      <Box
        style={
          size === "small"
            ? {
                width: 170,
                height: 170,
                boxShadow: themeExtension.shadows.softLightBlue,
              }
            : {
                width: 200,
                height: 200,
                boxShadow: themeExtension.shadows.softLightBlue,
              }
        }
        round="small"
        direction="column"
        justify="center"
        align="center"
        background="white"
      >
        <Box
          pad="xsmall"
          justify="center"
          align="center"
          // background="#D1E3EC"
          margin={{ bottom: "small" }}
          style={{ width: 120, height: 120 }}
        >
          {children}
        </Box>
        <Heading
          level={size === "small" ? "6" : "5"}
          size={size === "small" ? "xsmall" : ""}
          color="brand"
          margin="none"
        >
          {title}
        </Heading>
      </Box>
    </Box>
  )
}

export default BoxLabel
