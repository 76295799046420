import React from "react"
import { Blank } from "grommet-icons"

const Terminal = ({ style, ...rest }) => {
  return (
    <Blank style={{ height: "24px", width: "24px", ...style }} {...rest}>
      <svg viewBox="0 0 91 91" xmlns="http://www.w3.org/2000/svg">
        <path d="M81.016 80.215C82.156 80.215 83.08 79.292 83.08 78.152V54.626C83.08 53.485 82.155 52.563 81.016 52.563C79.877 52.563 78.952 53.486 78.952 54.626V78.151C78.951 79.292 79.876 80.215 81.016 80.215Z"></path>
        <path d="M73.537 80.153C74.678 80.153 75.601 79.23 75.601 78.09V53.69C75.601 52.549 74.677 51.627 73.537 51.627C72.397 51.627 71.474 52.55 71.474 53.69V78.09C71.474 79.23 72.397 80.153 73.537 80.153Z"></path>
        <path d="M39.237 79.844C40.377 79.844 41.301 78.92 41.301 77.781V48.847C41.301 47.706 40.377 46.784 39.237 46.784C38.097 46.784 37.173 47.707 37.173 48.847V77.78C37.173 78.92 38.097 79.844 39.237 79.844Z"></path>
        <path d="M89.625 47.518L50.892 25.693C52.834 23.907 54.061 21.353 54.061 18.513C54.061 13.835 50.754 9.91301 46.354 8.96301V3.54401C46.354 2.40301 45.43 1.48001 44.29 1.48001C43.149 1.48001 42.226 2.40301 42.226 3.54401V12.871H44.29C47.401 12.871 49.932 15.402 49.932 18.512C49.932 20.656 48.73 22.525 46.963 23.479L45.828 22.84C45.822 22.837 45.817 22.838 45.812 22.835C45.215 22.505 44.471 22.473 43.829 22.824L42.16 23.731C41.025 23.267 40.05 22.441 39.415 21.354C38.841 20.369 37.578 20.037 36.592 20.612C35.608 21.187 35.275 22.45 35.85 23.435C36.425 24.419 37.173 25.266 38.024 25.979L1.463 45.855C1.442 45.867 1.427 45.88 1.409 45.892C0.788003 46.256 0.384003 46.922 0.384003 47.668V85.816C0.384003 86.956 1.308 87.879 2.448 87.879H30.457C30.56 87.879 30.661 87.872 30.759 87.857H88.613C89.754 87.857 90.678 86.934 90.678 85.794V49.317C90.679 48.553 90.26 47.874 89.625 47.518ZM29.478 38.083L19.192 40.916L34.999 32.323L29.478 38.083ZM28.392 42.664V83.753H16.075V46.057L28.392 42.664ZM44.243 28.279C44.259 28.279 44.274 28.282 44.29 28.282C44.717 28.282 45.134 28.245 45.546 28.192L60.828 42.797L34.609 38.618L44.243 28.279ZM67.722 43.896C67.616 43.651 67.46 43.423 67.254 43.226L58.137 34.514L77.577 45.467L67.722 43.896ZM4.512 49.241L11.947 47.193V83.753H4.512V49.241ZM86.55 83.731H32.521V42.464L86.55 51.078V83.731Z"></path>
        <path d="M48.784 79.931C49.924 79.931 50.847 79.008 50.847 77.868V50.214C50.847 49.073 49.923 48.151 48.784 48.151C47.643 48.151 46.72 49.074 46.72 50.214V77.867C46.72 79.008 47.644 79.931 48.784 79.931Z"></path>
        <path d="M56.952 80.019C58.092 80.019 59.016 79.095 59.016 77.955V51.569C59.016 50.428 58.091 49.505 56.952 49.505C55.813 49.505 54.888 50.429 54.888 51.569V77.954C54.888 79.095 55.813 80.019 56.952 80.019Z"></path>
        <path d="M65.25 80.092C66.391 80.092 67.314 79.169 67.314 78.029V52.732C67.314 51.591 66.39 50.669 65.25 50.669C64.11 50.669 63.187 51.592 63.187 52.732V78.028C63.187 79.169 64.11 80.092 65.25 80.092Z"></path>
        <path d="M19.82 69.107C20.959 69.107 21.884 68.183 21.884 67.043V62.331C21.884 61.19 20.959 60.268 19.82 60.268C18.679 60.268 17.755 61.191 17.755 62.331V67.043C17.755 68.184 18.679 69.107 19.82 69.107Z"></path>
        <path d="M8.76999 61.776C7.62999 61.776 6.70599 62.7 6.70599 63.839V67.042C6.70599 68.183 7.62999 69.106 8.76999 69.106C9.90999 69.106 10.834 68.182 10.834 67.042V63.84C10.834 62.7 9.90999 61.776 8.76999 61.776Z"></path>
      </svg>
    </Blank>
  )
}

export default Terminal
