import { graphql, useStaticQuery } from "gatsby"

export const useGetProductTypes = () => {
  const { allMdx } = useStaticQuery(
    graphql`
      query productTypes {
        allMdx(
          filter: {
            frontmatter: {
              productTypes: {
                elemMatch: { identification: { eq: "productTypes" } }
              }
            }
          }
        ) {
          edges {
            node {
              frontmatter {
                productTypes {
                  tinyImage {
                    childImageSharp {
                      fluid(maxWidth: 500, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  image {
                    childImageSharp {
                      fluid(maxWidth: 1000, quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  identification
                  body
                  subtitle
                  name
                  publish
                }
              }
            }
          }
        }
      }
    `
  )
  return allMdx.edges[0].node.frontmatter.productTypes
}
