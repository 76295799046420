import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { Box, ResponsiveContext } from "grommet"

import Wrapper from "../../components/Container/Wrapper"
import { themeExtension } from "../../styles/theme"
import Title from "../../components/Typography/Title"
import BoxLabel from "../../components/Labels/BoxLabel"
import TopTriangle from "../../img/TopTriangle.svg"
import AdvancedSensory from "../../assets/AdvancedSensory.svg"
import ContainerLock from "../../assets/ContainerLock.svg"
import Magnet from "../../assets/Magnet.svg"
import SolarTracker from "../../assets/SolarTracker.svg"

const TriangleWrapperBox = styled(Box)`
  background: url(${TopTriangle}) top center no-repeat;
  width: 100%;
  margin-top: -3px;
  z-index: 1;
  background-color: ${themeExtension.colors.backgrounds.light};
`

const DeviceAgnostic = () => {
  const { heading_subtitle, heading, deviceTypes } = useDevicesData()
  console.log("TCL: DeviceAgnostic -> deviceTypes", deviceTypes)
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <TriangleWrapperBox
          background={themeExtension.colors.backgrounds.light}
        >
          <Wrapper>
            <Box
              direction="column"
              pad="medium"
              width="xxlarge"
              justify="center"
            >
              <Title
                title={heading}
                tlevel={2}
                subTitle={heading_subtitle}
                align="center"
                desc
              />
            </Box>
            <Box direction="row" justify="center" wrap>
              {/* {dataTypes.map(type => {
                return (
                  type.publish && (
                    <BoxLabel size={size} title={type.name}>
                      <ContainerLock />
                    </BoxLabel>
                  )
                )
              })} */}
              <BoxLabel size={size} title="Lock Mechanism">
                <ContainerLock />
              </BoxLabel>
              <BoxLabel size={size} title="Magnetic Attachment">
                <Magnet />
              </BoxLabel>
              <BoxLabel size={size} title="Solar Powered">
                <SolarTracker />
              </BoxLabel>
              <BoxLabel size={size} title="Advanced Sensors">
                <AdvancedSensory />
              </BoxLabel>
            </Box>
          </Wrapper>
        </TriangleWrapperBox>
      )}
    </ResponsiveContext.Consumer>
  )
}

export const useDevicesData = () => {
  const { allMdx } = useStaticQuery(
    graphql`
      query spoorDevices {
        allMdx(
          filter: { frontmatter: { identification: { eq: "spoorDevices" } } }
        ) {
          edges {
            node {
              frontmatter {
                heading_subtitle
                heading
                deviceTypes {
                  image {
                    childImageSharp {
                      fluid(maxWidth: 150, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  name
                  publish
                }
              }
            }
          }
        }
      }
    `
  )
  return allMdx.edges[0].node.frontmatter
}

export default DeviceAgnostic
