import React from "react"
import { navigate } from "gatsby"
import { Box, ResponsiveContext, Heading, Paragraph } from "grommet"
import styled from "styled-components"
import Img from "gatsby-image"

import { useGetPosts } from "../../hooks/useGetPosts"
import Wrapper from "../Container/Wrapper"

const PostBoxHeading = styled(Heading)`
  font-weight: 900;
  line-height: 30px;
  letter-spacing: -0.03em;
`

const PostBox = styled(Box)`
  width: 100%;
  margin-bottom: 48px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
  -webkit-transition: box-shadow 0.4s ease, -webkit-transform 0.4s ease;
  transition: box-shadow 0.4s ease, -webkit-transform 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease,
    -webkit-transform 0.4s ease;
  &:hover {
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
    -webkit-perspective: 1px;
    perspective: 1px;
    -webkit-transform: perspective(0) translate(0, -5px);
    transform: perspective(0) translate(0, -5px);
  }
  @media (min-width: 750px) {
    margin-bottom: 24px;
    width: 47%;
  }

  @media (min-width: 1024px) {
    width: 30%;
  }
`

const BlogPosts = () => {
  const data = useGetPosts()

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Wrapper>
          <Box direction="row" wrap gap="medium" justify="center">
            {data.map(post => (
              <PostBox
                // basis={size === "small" ? "1" : "1/3"}
                elevation="small"
                overflow="hidden"
                direction="column"
                round="small"
                hoverIndicator={{ boxShadow: "1px 3px 1px #9E9E9E" }}
                onClick={() => {
                  navigate(post.slug)
                }}
                margin={{ bottom: 24 }}
              >
                <Img fluid={post.featuredImage} />
                <Box pad="medium">
                  <Paragraph
                    color="dark-4"
                    size="small"
                    margin={{ vertical: "none" }}
                    style={{ textTransform: "uppercase" }}
                  >
                    {post.category}
                  </Paragraph>
                  <PostBoxHeading
                    color="brand"
                    level="3"
                    size="medium"
                    margin={{ top: "small", bottom: "none" }}
                  >
                    {post.title}
                  </PostBoxHeading>
                </Box>
              </PostBox>
            ))}
          </Box>
        </Wrapper>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default BlogPosts
