import React from "react"
import { Box } from "grommet"

const HollowCircle = ({ align, color }) => {
  const alignStyleBottom =
    align === "right" ? { marginRight: 5, right: 0 } : { marginLeft: 5 }
  const alignStyleTop =
    align === "right" ? { marginRight: 12, right: 0 } : { marginLeft: 12 }
  return (
    <>
      <Box
        background={color}
        margin={align ? { left: "small" } : { right: "small" }}
        style={{ width: 38, height: 38, borderRadius: 50, opacity: 0.2 }}
      />
      <Box
        background={color}
        style={{
          position: "absolute",
          width: 28,
          height: 28,
          borderRadius: 50,
          opacity: 0.4,
          ...alignStyleBottom,
        }}
      />
      <Box
        background={color}
        style={{
          position: "absolute",
          width: 14,
          height: 14,
          borderRadius: 50,
          opacity: 1,
          ...alignStyleTop,
        }}
      />
    </>
  )
}

export default HollowCircle
